.total-row-leader-shift {
  background-color: #f7f8f9 !important;
  font-weight: bold;
}

/* Remove borders from child columns while keeping the table border */
.custom-table .ant-table-thead > tr > th {
  border-right: none !important;
}

.custom-table .ant-table-tbody > tr > td:not(:first-child) {
  border-right: none !important;
}

.custom-table .ant-table-thead > tr > th:last-child,
.custom-table .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #d9d9d9 !important;
}

/* Remove right border from all headers except the first top-left one */
.custom-table .ant-table-thead > tr:nth-child(1) > th:not(:last-child),
.custom-table .ant-table-thead > tr:nth-child(2) > th:nth-child(3),
.custom-table .ant-table-tbody > tr > td:first-child,
.custom-table .ant-table-tbody > tr > td:nth-child(4) {
  border-right: 2px solid #d9d9d9 !important;
}

.custom-table .ant-table-thead > tr:first-child > th:not(:first-child) {
  border-bottom: 2px solid #d9d9d9 !important;
}

.custom-table .ant-table-tbody > tr > td:not(:first-child) {
  width: 240px;
  padding: 8px 0px 8px 65px !important;
}
.custom-table .ant-table-thead > tr:not(:first-child) > th {
  padding: 14px 0px 14px 65px !important;
}

.leader_shift_modal .ant-modal-content .ant-modal-header {
  padding-left: 1.5rem;
  border-bottom: none !important;
}
