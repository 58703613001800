.popover-filter .ant-popover-arrow {
  display: none;
}
.popover-filter .ant-popover-inner {
  box-shadow: 0px 6px 16px #00000029;
  // border: 1px solid #adadad;
  border-radius: 12px;
  padding: 16px;
}
.popover-filter .ant-popover-inner .ant-popover-title {
  padding: 0px 0px 16px 0px;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #878787;
}
.popover-filter .ant-popover-inner .ant-popover-inner-content {
  padding: 0px;
}

.popover-filter .ant-popover-inner-content .ant-radio-wrapper,
.popover-filter .ant-popover-inner-content .ant-checkbox-wrapper {
  font-size: 14px !important;
  line-height: 32px;
}

.filter-popover {
  border: 1px solid #ebebed;
  cursor: pointer;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  transition: all;
  font-size: 16px;
}
.filter-popover.active,
.filter-popover:hover {
  @apply bg-hoverColor1;
}
.filter-popover.active {
  @apply border-mainColor1 bg-backgroundColor2;
}

.emoji-popover .emoji-popover-inner {
  @apply p-0;
}
.emoji-popover-placement-bottomRight > .emoji-popover-arrow {
  @apply right-2;
}
