.ant-btn:not(.ant-btn-circle) {
  @apply h-10 font-semibold;
}

.ant-btn-link {
  @apply font-medium text-colorPrimary underline;

  background: transparent;
  border: 0;
  border-radius: 8px;

  height: fit-content;
  margin: -7px -8px;
  padding: 7px 8px;
  width: fit-content;
}

.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  @apply border-0 bg-colorBgIconHover text-colorPrimary;
}
.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
  @apply brightness-125;
}
.ant-btn-lg {
  @apply h-12 px-9 text-base font-semibold;
}

.ant-btn-icon-only {
  @apply h-8 w-8;
}

.active-btn {
  @apply bg-colorBgSpotlight text-white;
  .header-badge {
    @apply text-white;
  }
}

.custom-button {
  position: relative;
  padding-left: 0;
  color: black; /* Màu chữ mặc định */
  background-color: transparent; /* Không có nền */
  border: 2px solid var(--btn-color); /* Màu viền */
  border-radius: 4px;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}

.custom-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px; /* Vị trí của hình tròn */
  width: 12px; /* Kích thước hình tròn */
  height: 12px;
  background-color: var(--btn-color);
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.custom-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px; /* Expand bắt đầu từ vị trí hình tròn */
  width: 0;
  height: 0;
  background-color: var(--btn-color);
  border-radius: 50%;
  z-index: 0;
  transition: width 0.4s ease, height 0.4s ease, top 0.4s ease, left 0.4s ease;
  transform: translate(-50%, -50%);
}

.custom-button:hover::after {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.custom-button:hover {
  color: white !important; /* Màu chữ chuyển thành trắng khi hover */
}

.button-content {
  position: relative;
  z-index: 1; /* Nội dung nút luôn ở trên cùng */
}



