.ant-card-actions {
  background-color: #f5f4f6 !important;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: none !important;
}

.custom-pagination.ant-pagination.css-dev-only-do-not-override-1a7knct .ant-pagination-prev,
.custom-pagination.ant-pagination.css-dev-only-do-not-override-1a7knct .ant-pagination-next {
  width: 24px !important;
  min-width: 2px;
}

.custom-request-tabs .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: #222a41;
  color: white;
  border-radius: 18px;
  padding: 8px 16px;
  font-weight: bold;
}

.custom-request-tabs :where(.css-dev-only-do-not-override-1a7knct).ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 8px;
}

.request-form :where(.css-dev-only-do-not-override-1a7knct).ant-badge .ant-badge-count {
  box-shadow: none;
}

.ant-tabs-tab.ant-tabs-tab-active .request-form-badge .ant-scroll-number.ant-badge-count {
  background-color: #4d5664 !important;
  color: #ffffff;
  font-weight: 600;
}
.ant-tabs-tab .request-form-badge .ant-scroll-number.ant-badge-count {
  background-color: #ebebed !important;
  color: #222a41;
  font-weight: 600;
}

.request-form-detail-card .ant-card-head {
  background-color: #f5f5f7;
  min-height: 4rem;
}
.request-form-detail-card .ant-card-body,
.confirm-modal .ant-card-body {
  padding: 0 !important;
}
