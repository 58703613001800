// .ant-card-head {
//   padding-left: 20px !important;
//   padding-right: 20px !important;
// }
  
// .ant-card-body {
//   padding-left:0 !important;
//   padding-right: 0 !important;
// }

.card-body-padding-none-override{
  .ant-card-body{
    @apply px-0
  }
}

.card-body-p-none-override{
  .ant-card-body{
    @apply p-0
  }
}

.card-body-px5-override{
  .ant-card-body{
    @apply px-5
  }
}

.ant-tabs-ink-bar{
  display: none;
}

.ant-card-head-border-bottom-none{
  .ant-card-head{
    @apply border-b-0
  }
}

.ant-card-head-p-5{
  .ant-card-head{
    @apply px-5
  }
}

.ant-card-body-pt-none{
  .ant-card-body{
    @apply pt-0
  }
}
