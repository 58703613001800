.ant-picker-outlined {
  @apply min-h-10 border-colorBorderInput bg-colorBgInput;
}
.float-label {
  .ant-picker-outlined {
    @apply min-h-float-label-height;
  }
  .ant-picker-clear {
    @apply right-4;
  }
}

.float-label:not(.no-label) {
  .ant-picker-outlined {
    @apply p-float-label-padding;
  }
}
