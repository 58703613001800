.timeline-tab .ant-timeline {
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
  }
  .ant-timeline-item-content {
    margin-inline-start: 24px;
  }
  .ant-timeline-item-tail {
    inset-block-start: 14px;
  }
  .ant-timeline-item-head-custom {
    margin-top: 3px;
  }
}
