.slice-right {
  opacity: 0; /* Bắt đầu ẩn */
  transform: translateX(-80%); /* Dịch hoàn toàn sang bên trái */
  animation: sliceRight 0.3s ease-out forwards; /* Chạy animation */
}

/* Định nghĩa animation */
@keyframes sliceRight {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.logo-fade-in {
  opacity: 0;
  animation: fadeIn 0.8s normal 1100ms forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
