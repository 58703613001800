/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Ẩn thanh cuộn trong Firefox */
}

::-webkit-scrollbar {
  width: 6px;
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #87878784;
  border-radius: 6px;
  width: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}

.slim-scrollbar::-webkit-scrollbar {
  width: 6px;
  display: block;
}
.slim-scrollbar::-webkit-scrollbar-thumb {
  background: #87878784;
  border-radius: 6px;
  width: 6px;
}
.slim-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.slim-scrollbar-horizontal::-webkit-scrollbar {
  height: 6px;
  display: block;
}
.slim-scrollbar-horizontal::-webkit-scrollbar-thumb {
  background: #87878784;
  border-radius: 6px;
  height: 6px;
}
.slim-scrollbar-horizontal::-webkit-scrollbar-track {
  border-radius: 10px;
}

.fade-scrollbar::-webkit-scrollbar {
  width: 6px;
  display: block;
}
.fade-scrollbar::-webkit-scrollbar-thumb {
  transition: all 0.75;
  width: 0px;
  // background: transparent;
}
.fade-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #87878784;
  border-radius: 6px;
  width: 6px;
  transition: all 0.5;
}
.fade-scrollbar:hover::-webkit-scrollbar-track {
  border-radius: 10px;
}
