.ant-select.ant-select-in-form-item,
.ant-select-selector,
.ant-select-single {
  @apply min-h-10;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-colorBorderInput bg-colorBgInput;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  @apply h-10;
}
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  @apply bg-colorBgContainerDisabled;
}

.float-label {
  .ant-select-selector,
  .ant-select-outlined {
    @apply min-h-float-label-height;
  }
}
.float-label:not(.no-label) {
  .ant-select-selector,
  .ant-select-selector > .ant-select-selection-search:first-child {
    left: 0 !important;
    padding: var(--float-label-padding) !important;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0px !important;
  }
  .ant-select-multiple {
    .ant-select-selector,
    .ant-select-selector > .ant-select-selection-search:first-child {
      left: 0 !important;
      padding: 20px 11px 0px 11px !important;
    }
  }
}

.custom-ant-select-selection-item {
  .ant-select-selection-item {
    @apply text-left text-[24px]/[32px] font-semibold tracking-normal text-[#141414] opacity-100;
  }
}

.ant-select-dropdown {
  @apply max-h-[40vh] overflow-auto;
}
