a.ant-typography,
.ant-typography a {
  @apply text-colorTextBase;
  font-family: inherit;
  text-decoration: underline;
}

.text-header-title {
  @apply font-bold;
}
